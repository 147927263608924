input[type=range] {
  height: 31px;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 15px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #74adfc;
  background: #02dce8;
  border-radius: 14px;
  border: 0px solid #000000;
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background: #02dce8;
  cursor: pointer;
  margin-top: -5.5px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: radial-gradient(#026de8, #48005e);
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #24ad46;
  background: linear-gradient(purple,red);
  border-radius: 14px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: linear-gradient(purple,red);
  border: 0px solid #000000;
  border-radius: 28px;
  box-shadow: 1px 1px 1px #239251;
}
input[type=range]::-ms-fill-upper {
  background: #11d622;
  border: 0px solid #000000;
  border-radius: 28px;
  box-shadow: 1px 1px 1px #50555C;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 35px;
  width: 35px;
  border-radius: 50px;
  background: #7ae152;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: linear-gradient(purple,red);
}
input[type=range]:focus::-ms-fill-upper {
  background: linear-gradient(purple,red);
}