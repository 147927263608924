.containerAbout{
  width: 100%;
  height: 50%;
  margin: 0;

}

.secciones img {
  width: 15%;
  height: 13%;
  margin-right: auto;
}

.secciones_Solicitar_Credito img {
  width: 15%;
  height: 100%;
  margin-right: 8%;
}



@media only screen and (max-width: 991px) {
    .azul{
      background-color: #02dce8!important;
      color: black !important;
      border: 1px solid #ffffff !important;
      margin-top: 5%;
      margin-left: 0% !important;
    }

    .azul_login{
      background-color: #02dce8!important;
      color: white !important;
      border: 1px solid #ffffff !important;
      margin-top: 5%;
      margin-left: 0% !important;
      font-size:large;
      font-weight: bolder;
      font-family: 'Poppins', sans-serif;
    }

    .text-title{
      color:rgb(243, 243, 243);
      font-size:x-large !important; 
      font-weight: bolder;
      font-family: 'Poppins', sans-serif;
    }

    .Titulo_preg_frec{
      font-size:xx-large !important;
    }
    
    .secciones img {
      width: 22%;
      height: 22%;
      margin-right: auto;
    }
    
    .secciones_Solicitar_Credito img {
      width: 25%;
      height: 100%;
      margin-right: 0%;
    }
}

@media only screen and (min-width: 992px) {
  
  .azul_login{
    color: #02dce8 !important;
    font-size:large;
    font-weight: bolder;
    font-family: 'Poppins', sans-serif;
  }
}

.text-title_nosotros{
  font-size:xx-large !important; 
  font-weight: bolder;
  font-family: 'Poppins', sans-serif;
}

.text-text-subtitle_nosotros{
  font-size:large !important; 
  font-weight: bolder;
  font-family: 'Poppins', sans-serif;
}


.containerPdf{
  align-self: center;
  background-color: red;
  width:80%;
 
  align-items: center;
  align-content: center;
}
.containerPdfMovements{
  position: relative;
  align-self: center;
  align-items: center;
  width: 2.5vh;
  align-content: center;
}
.containerPdfConsolidated{
  
  position: relative;
  width: 15vh;
  align-content: center;
}

.como-f{
    padding: 0;
    height: 50%;
}
.text-title{
  color:rgb(243, 243, 243);
  font-size:medium;
  font-weight: bolder;
  font-family: 'Poppins', sans-serif;
}
.text-home{
    color:rgb(255, 255, 255);
    font-size:large;
    font-size:6ch;
    font-weight: bolder;
    font-family: 'Poppins', sans-serif;
  }
  .text-session{
    color:rgb(255, 255, 255);
    font-size:2ch;
    font-weight: bolder;
    font-family: 'Poppins', sans-serif;
  }
  .text-home-black{
    color:rgb(0, 0, 0);
    font-size:small;
    font-family: 'Poppins', sans-serif;
  }
.text-title-black{
    color:black;
    font-size:medium;
    font-weight: bolder;
    font-family: 'Poppins', sans-serif;
  }
  .text-title-red{
    color:rgb(250, 3, 3);
    font-size:medium;
    font-weight: bolder;
    font-family: 'Poppins', sans-serif;
  }
  .ruta{
    font-size:4ch ;
  }
  .text-title-blue{color:#02dce8;
    font-size: 2ch;
    font-weight: bolder;
    font-family: 'Poppins', sans-serif;}
  .text-total{
    color:#02dce8;
    font-size:4ch;
    font-weight: bolder;
    font-family: 'Poppins', sans-serif;
  }
  .text-total-black{
    color:#020202;
    font-size:2.5ch;
    font-weight: bolder;
    font-family: 'Poppins', sans-serif;
  }
  .text-table{
    color:#02dce8;
    font-size:2ch;
    font-weight: bolder;
    font-family: 'Poppins', sans-serif;
  }
  .text-table-cuotas{
    color:#02dce8;
    font-size:1.5ch;
    font-weight: bolder;
    font-family: 'Poppins', sans-serif;
  }
.text-body{
    color:'#fff';
    font-size:medium;
    font-weight:bolder;
    font-family: 'Poppins', sans-serif;
  }

.titulo{
    text-align: center;
    font-weight: bold;
    margin-bottom: 2rem;
    color: rgba(0,0,0,0.7);
}

.titulo h3{
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    margin: .5rem 0 1rem;
}
.titulo p{
    text-transform: lowercase;
    font-size: 1.4rem;
}
.secciones{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
    
}
.como-funciona-pasos p{
    text-transform: lowercase;
    font-size: 1.2rem;
}

.pasos-item{
    color: #f1ecf1;
    height: 22rem;
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    z-index: 1;
    border-radius: 50px;
    
     
}


.pasos-item::before{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 50px;
}






