.container{
    width: 100%;
    height: 50%;
    margin: 0;
    padding: 0 1.5rem;
}
.como-funciona{
    padding: 0;
    height: 50%;
}

.titulo{
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 2rem;
    color: rgba(0,0,0,0.7);
}

.titulo h3{
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    margin: .5rem 0 1rem;
}
.titulo p{
    text-transform: lowercase;
    font-size: 1.4rem;
}
.como-funciona-pasos{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(19rem,1fr));
    grid-gap: 2.5rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    margin: .5rem 0 1rem;
    text-align: justify;
    text-transform: capitalize;
    
}
.como-funciona-pasos p{
    text-transform: lowercase;
    font-size: 1.2rem;
}

.pasos-item{
    color: #f1ecf1;
    height: 22rem;
    padding: 3rem;
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    z-index: 1;
    border-radius: 50px;
    box-shadow: 0px 3px 6px 1px rgba(77, 53, 156, 0.6); 
    
     
}
.qr-item{
    color: #f1ecf1;
    height: 22rem;
    padding: 3rem;
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    z-index: 1;
    border-radius: 50px;
    
    
     
}

.pasos-item::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(135deg, rgba(122, 185, 236, 0.6) 0%, rgba(70, 118, 206, 0.5) 100%);
    z-index: -1;
    border-radius: 50px;
}

.pasos-1{
    background:url("../../../assets/stars.jpg") center no-repeat;
}
.pasos-2{
    background:url("../../../assets/hiking.jpg") center no-repeat;
}
.pasos-3{
    background:url("../../../assets/camping.jpg") center no-repeat;
}

