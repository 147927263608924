/*Estilo para dispositivos móviles */
@media screen and (max-width: 600px){

    .root-container {
        width: 100%;
        height: 100%;
       justify-content: center;
        align-items: center;
        flex-direction: column;
        display: flex;
    } 
        
    
    .controller {
        flex: 1;
        text-align: center;
        height: 120%;
        line-height: 2;
        cursor: pointer;
        background-color: #ffff;
        
    }
    
    
    .selected-controller {
        transition: border 0.5s ease-out;
        border-bottom: 5px solid   #02dce8 ;
    }

    .box-controller{
        display: block;
        width: 100%;
    }
}

/*Estilo para dispositivos no móviles */
@media screen and (min-width: 600px){
    html,
    body,
    #root {
        width: 100%;
        height: 100%;
        box-sizing: border-box;  
    }
    
    
    .root-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    #startSession,#register{
        display: inline;
        width: 30%;
        text-align: center;
        flex-direction: row;
    }

    .box-controller {
        visibility: visible;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        max-width: 100%;
        min-width: 100%;
        height: 35px;
        -webkit-box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.2);
        box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.2);
        margin-bottom: 30px;
        align-items: center;
        transition: visibility 0.5s ease-out;
      
    }
    
    .controller {
        flex: 1;
        text-align: center;
        height: 120%;
        line-height: 2;
        cursor: pointer;
        background-color: #ffff;
        
    }
    
    
    .selected-controller {
        transition: border 0.5s ease-out;
        border-bottom: 5px solid   #02dce8 ;
    }
    
    .box-container {
        display: flex;
        flex-direction: column;
        max-width: 24em;
        min-width: 21em;
        box-shadow: 0px 0px 20px 0px rgba(15, 15, 15, 0.2);
        border-radius: 6px;
        padding: 24px;
        background-color:  #ffffff;;
    }
    
    .inner-container {
        transition: visibility 0.2s ease-out;
        color:  #ffffff;
    }
    
    .inner-container.show {
        visibility: visible;
    }
    
    
    .header {
        text-align: center;
        padding: 5px;
        margin-bottom: 17px;
        /* margin-left: 35px; */
        /* margin-right: 35px; */
        font-family: 'Montserrat', sans-serif;
        font-size: 22px;
        border-bottom: 2px solid  #02dce8;
        
    }
    
    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }
    
    .input-group {
        
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        margin-bottom: 5px;
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
     
        color:  #000000;
    }
    
    
    .login-label {
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-weight: 600;
        padding-left: 5px;
        
    
        
    }
    
    
    .login-input {
        height: 2.1em;
        border-radius: 3px;
        border: 1px solid ;
        transition: border 0.4s ease-out;
        padding: 13px;
        font-family: 'Montserrat', sans-serif;
        font-size: 19px;
      
        background: rgba(15, 15, 15, 0.01);
        background-color: #ffffff;
    }
    
    .login-input:hover {
        border: 2px solid ;
    }
    
    .login-input:focus {
        border: 2px solid #6565e7;
        box-shadow: 0px 0px 20px rgba(15, 15, 15, 0.2);
    }
    
    .login-input::placeholder {
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        color: rgba(15, 15, 15, 0.4);
    }
    
    .login-btn {
        padding: 2px 30px;
        /* background-color: #417cef; */
        border: 0;
        font-size: 18px;
        border-radius: 3px;
        font-family: 'Montserrat', sans-serif;
        background-color: rgba(15, 15, 15, 0.03);
        margin-top: 20px;
        border: 2px solid #000000;
        transition: background-color 0.3s ease-out;
        cursor: pointer;
    
    }
    
    .login-btn:hover,
    .login-btn:focus {
        background-color: #494949;
        color: white;
    }
    
    .danger-error {
        color: #e74c3c;
        font-size: 16px;
    }
    
    
    .password-state {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
    }
    
    .pwd {
        height: 6px;
        flex: 1;
        visibility: hidden;
    }
    
    .pwd-weak {
        background-color: #e74c3c;
    }
    
    .pwd-medium {
        background-color: #3498db;
    }
    
    .pwd-strong {
        background-color: #2ecc71;
    }
    
    .show {
        visibility: visible;
    }
}

