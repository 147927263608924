
.sidenav {
  position: fixed;
  text-align: left;
  word-wrap: break-word;
  width: 220px;
  background: #02dce8;
  
}
.navbar {
  position: fixed;
  top: 0;
  background-color: transparent;
  width: 100%;
  height: 80px;
  padding-left: 50px;
  font-size: 20px;
  z-index: 1;
}

.scrolling {
  background-color: rgb(231, 42, 42);
  transition: all 0.5s ease;
}
.text-title{
  color:blanchedalmond;
  font-size:medium;
  font-weight: 900;
  font-family: 'Montserrat', sans-serif;
}

.sidenav header{
  font-size: 30px;
  text-align: center;
  line-height: 50px;
  background:#02dce8;
}
.sidenav ul label{
 
  display: block;
  height: 100%;
  width: 100%;
  line-height: 200%;
  font-size: 18px;
  color: rgb(12, 12, 12);
  padding-left: 20px;
  box-sizing: border-box;
  border-top: 1px solid rgba(255, 255, 255, .1);
  border-bottom: 2px solid white;
  transition: .4s;
}
ul li:hover label {
  padding-left: 35px;
}

.sidenav ul label {
  margin-right: 6px;
}

.main {
  margin-left: 230px; /* Same width as the sidebar + left position in px */
  font-size: 28px; /* Increased text to enable scrolling */
  padding: 0px 10px;
}

@media screen and (max-width: 700px) {
  .sidenav {
    width: 100%;
    height: auto;
    position: relative;
  }
  .main { margin-left: 0px}
  
  .sidenav {float: left;}
  div.content {margin-left: 0;}
}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 400px) {
  .sidenav a {
    text-align: center;
    float: none;
  }
  .main { margin-left: 0px}
}