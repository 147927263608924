*,
*::before,
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.container{
    width: 100%;
    min-width: 100%;
    margin: 0;
    padding: 0 1.5rem;
    text-align: center;
}


.main-message{
    width: 50%;
    color:rgb(0, 0, 0);
    text-transform: uppercase;
    transform: translateY(10%);
   
}
.main-message h3{
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
    margin: 1rem 0;
}
.main-message p{
    font-size: 1.3rem;
}

@media only screen and (max-width: 991px) {
    .section1{
    background: url("../../assets/manos.jpg") center no-repeat;
    position: relative;
    background-size:cover;
    background-position: top;
    /* background-attachment: fixed; */
    margin-bottom: 1rem;
    }

}

@media only screen and (min-width: 992px) {
    .section1{
    background: url("../../assets/manos.jpg") center no-repeat;
    position: relative;
    background-size:cover;
    background-position: top;
    background-attachment: fixed;
    margin-bottom: 1rem;
    }

}

.section1::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 15rem;
}

.section2{
    position: relative;
}
.section2::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 16rem;
}
